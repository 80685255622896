<template>
  <form action="">
    <div class="field">
      <input type="text" v-model="username" />
    </div>
    <div class="field">
      <input type="text" v-model="password" />
    </div>
    <div class="field">
      <a href @click.prevent="submit">送出</a>
    </div>
  </form>
</template>

<script>
import { reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const router = useRouter();
    const form = reactive({
      username: '',
      password: '',
    });
    const submit = () => {
      router.push({ name: 'Member' });
    };

    return { submit, ...toRefs(form) };
  },
};
</script>

<style></style>
